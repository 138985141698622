<template>
  <div>
    <!-- 头图 -->
    <div class="merchantSettled">
      <div>
        <img src="@/assets/img/MerchantSettled/bannerbg_sjglptai2.jpg" />
      </div>
      <div class="merchantSettled_box">
        <div class="merchantSettled_left">
          <div class="merchantSettled_leftTitle">
            <div>程序展示</div>
            <div>Program Show</div>
          </div>
          <!-- <div class="merchantSettled_leftBtn">
            <div>
              <div>申请入驻</div>
            </div>
            <div>
              <div>商家登录</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 流程 -->
    <div class="process">
      <div class="process_left">
        <div>
          <div class="process_leftImg">
            <img src="@/assets/img/MerchantSettled/ic_glswu.png" />
          </div>
          <div class="process_leftInfo">
            <div>关联小区食物</div>
            <div>
              与冰眸用户直接联动，提供服务给小区住户，平台会根据用户的食物消耗推荐，销售目标直达
            </div>
          </div>
        </div>
        <div>
          <img src="@/assets/img/MerchantSettled/pic_glswu.png" />
        </div>
      </div>
      <div class="process_right">
        <div>
          <img src="@/assets/img/MerchantSettled/pic_tszzhuan.png" />
        </div>
        <div>
          <div class="process_rightInfo">
            <div>提升食物周转</div>
            <div>
              多端应用，搭配手机app更高效移动处理订单、上架、盘点，随时随地管理店铺，网页、app数据实时同步
            </div>
          </div>
          <div class="process_rightImg">
            <img src="@/assets/img/MerchantSettled/ic_tszzhuan.png" />
          </div>
        </div>
      </div>
      <div class="process_left">
        <div>
          <div class="process_leftImg">
            <img src="@/assets/img/MerchantSettled/ic_sxfbian.png" />
          </div>
          <div class="process_leftInfo">
            <div>省心方便实惠</div>
            <div>
              免安装、免维护，终身免费升级，降低店铺信息化成本，数据安全可靠，免费移动进销存管理系统
            </div>
          </div>
        </div>
        <div>
          <img src="@/assets/img/MerchantSettled/pic_sxfbian.png" />
        </div>
      </div>
    </div>
    <!-- 申请 -->
    <!-- <div class="application">
      <div>
        <div class="application_title">
          <div>免费试用</div>
          <div>免费注册试用，如需交易请提交审核文件上</div>
        </div>
        <div class="application_box">
          <div class="application_boxLeft">
            <div>
              <div class="application_boxLeftItem">
                <div>店铺名称：</div>
                <div>
                  <input type="text" />
                </div>
              </div>
              <div class="application_boxLeftItem">
                <div>用户名：</div>
                <div>
                  <input type="text" />
                </div>
              </div>
              <div class="application_boxLeftItem">
                <div>手机号码：</div>
                <div>
                  <input type="text" />
                </div>
              </div>
              <div class="application_boxLeftItem">
                <div>联系人：</div>
                <div>
                  <input type="text" />
                </div>
              </div>
              <div class="application_boxLeftItem">
                <div>邮箱：</div>
                <div>
                  <input type="text" />
                </div>
              </div>
            </div>
            <div>
              <div>提交注册</div>
            </div>
          </div>
          <div class="application_boxRight">
            一、预包装/散装商品，须提供：<br />
            1.营业执照；<br />
            2.《食品经营许可证》或《食品生产许可证》；<br />
            3.经营预包装食品：营业执照住所在自贸区内的卖家，可提供上述资质或提供经营范围包含食品经营的营业执照及监管部门要求的其他文件（如：预包装食品销售备案通知书等）；<br />
            二、小作坊制售商品，须持有营业执照和小作坊登记证；<br />
            三、食品添加剂生产厂家，须持有营业执照和食品添加剂生产许可证。<br />
            四、特许养殖类商品，须提供：<br />
            1.发布的肉类商品，须提供《野生动物经营利用许可证》。<br />
            2.发布水产类商品，须提供《水生野生动物经营利用许可证》。<br />
            3.本平台暂时不支持饮用酒类的销售，料酒及其他用途酒或含酒精类产吕须标明其他用途。
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
/* 头图 */
.merchantSettled {
  height: 420px;
  position: relative;
  display: flex;
}
.merchantSettled > div:nth-of-type(1) img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.merchantSettled_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.merchantSettled_left {
  color: #fff;
  width: 450px;
}
.merchantSettled_leftTitle > div:nth-of-type(1) {
  font-size: 60px;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
}
.merchantSettled_leftTitle > div:nth-of-type(2) {
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  margin-top: 18px;
}
.merchantSettled_leftBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 98px;
}
.merchantSettled_leftBtn > div > div {
  width: 195px;
  height: 68px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.merchantSettled_leftBtn > div > div:hover {
  background-color: #cccccc59;
  border: 1px solid #cccccc59;
}
.merchantSettled_right {
  display: flex;
  width: 222px;
  height: 450px;
}
.merchantSettled_right img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 流程 */
.process {
  width: 1440px;
  margin: 200px auto;
}
.process_left {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.process_left > div:nth-of-type(1) {
  display: flex;
  justify-content: flex-start;
}
.process_left > div:nth-of-type(2) {
  width: 530px;
  /* height: 530px; */
}
.process_left > div:nth-of-type(2) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}
.process_leftImg {
  width: 36px;
  height: 36px;
  position: relative;
  right: -18px;
  top: 10px;
  z-index: -1;
}
.process_leftImg img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.process_leftInfo {
  width: 460px;
}
.process_leftInfo > div:nth-of-type(1) {
  font-size: 36px;
  font-weight: 500;
  color: #333333;
  line-height: 54px;
}
.process_leftInfo > div:nth-of-type(2) {
  font-size: 24px;
  color: #999999;
  line-height: 36px;
  margin-top: 10px;
}

.process_right {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.process_right > div:nth-of-type(1) {
  width: 530px;
  /* height: 530px; */
}
.process_right > div:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
}
.process_right > div:nth-of-type(1) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}
.process_rightImg {
  width: 36px;
  height: 36px;
  position: relative;
  left: -18px;
  top: 10px;
  z-index: -1;
}
.process_rightImg img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.process_rightInfo {
  width: 460px;
}
.process_rightInfo > div:nth-of-type(1) {
  font-size: 36px;
  font-weight: 500;
  color: #333333;
  line-height: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.process_rightInfo > div:nth-of-type(2) {
  font-size: 24px;
  color: #999999;
  line-height: 36px;
  margin-top: 10px;
}
/* 免费试用 */
.application {
  background-color: #f0f0f0;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.application > div {
  width: 1440px;
}
.application_title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.application_title > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.application_title > div:nth-of-type(2) {
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  margin-top: 20px;
}
.application_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
}
.application_box > div {
  width: 50%;
}
.application_boxLeft > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.application_boxLeft > div:nth-of-type(2) > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 278px;
  height: 60px;
  background: #49c0c5;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 34px;
  cursor: pointer;
}
.application_boxLeft > div:nth-of-type(2) > div:hover {
  background: #329195;
}
.application_boxLeftItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.application_boxLeftItem input {
  border: none;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  width: 400px;
  outline: none;
  padding: 5px;
}
.application_boxRight {
  font-size: 20px;
  font-weight: 400;
  color: #666666;
  line-height: 34px;
}
</style>